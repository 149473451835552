/*

useOldProjectsRevealAnimation


    .anim-reveal-parent
        The trigger element for scrollTrigger and also has opacity and Y values transformed


    .anim-reveal-child
        The inner element which has a scale transform


*/

import { useEffect } from "react"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"

const useOldProjectsRevealAnimation = () => {
  const delayBeforeApplying = 1200 //+ olderProjectsData.length * 200

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    // const revealAnimationItems = ".img-container, .panel .text"

    const revealAnimationItems = ".anim-reveal-parent"
    gsap.set(revealAnimationItems, { opacity: 0, y: 80 })
    gsap.set(".anim-reveal-child", { scale: 1.1 })

    setTimeout(
      () =>
        ScrollTrigger.batch(revealAnimationItems, {
          interval: 0.3, // time window (in seconds) for batching to occur.
          //   batchMax: 100, // maximum batch size (targets). Can be function-based for dynamic values
          start: "top 90%",
          onEnter: batch => {
            const tl = gsap.timeline()
            tl.add("start")
            tl.to(batch, {
              opacity: 1,
              y: 0,
              duration: 1.2,
              stagger: 0.1,
              ease: "power2.out",
            })

            batch.forEach((item, index) => {
              tl.to(
                item.querySelector(".anim-reveal-child"),
                {
                  scale: 1,
                  duration: 1.2,
                  ease: "power2.out",
                },
                `start+=${index * 0.1}`
              )
            })
          },
        }),
      delayBeforeApplying
    )
  }, [])
}

export default useOldProjectsRevealAnimation
