import gsap from "gsap"
import React, { useEffect } from "react"
import useExitAnimationFade from "../../animations/useExitAnimationFade"
import useOldProjectsRevealAnimation from "../../animations/useOldProjectsRevealAnimation"
import Image from "../../assets/images/Image"

import { select, selectAll } from "../../utils/helpers"
import ScrollToEl from "../../utils/scrollToEl"

import ComparisonImage from "../ComparisonImage"
import SEO from "../seo"

import "./index.scss"

import olderProjectsData from "./olderProjectsData"

const useOlderProjectsHeroAnimation = () => {
  useEffect(() => {
    const introHeading = select(".intro-section__heading")
    const introDescription = select(".intro-section__description")
    const introNavigationItems = selectAll(".intro-section__navigation  h5")

    console.log(introNavigationItems)

    const tl = gsap.timeline({
      defaults: {
        ease: "power3.inOut",
        duration: 2,
      },
    })
    tl.add("start")
      .set(".older-projects", { pointerEvents: "none", visibility: "visible" })
      .fromTo(
        introHeading,
        { clipPath: "inset(0% 100% 0% 0%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 2.4 },
        "start"
      )
      .fromTo(
        introDescription,
        { clipPath: "inset(0% 100% 0% 0%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 2.4 },
        "start+=0.4"
      )

      .fromTo(
        introNavigationItems,
        // { opacity: 0 },
        // { opacity: 1, stagger: 0.2, duration: 0.4 },
        { clipPath: "inset(0% 100% 0% 0%)" },
        { clipPath: "inset(0% 0% 0% 0%)", stagger: 0.2, duration: 2 },
        "start+=0.8"
      )
      .set(".older-projects", { pointerEvents: "" })
  }, [])
}

const OlderProjectsPage = ({ data: pageData }) => {
  useOlderProjectsHeroAnimation()
  useOldProjectsRevealAnimation()
  useExitAnimationFade()

  console.log(pageData)

  return (
    <div className="page older-projects" style={{ visibility: "hidden" }}>
      <SEO title="Older projects" />
      <section className="intro-section">
        <div className="container mx-auto">
          <h1 className="intro-section__heading">Other Projects.</h1>
          <br />
          {/* <p className="intro-section__description">
            These are some of the projects we worked on before forming Stella.
          </p> */}
          {/* <div className="intro-section__navigation">
            {olderProjectsData.map((item, index) => (
              <div key={index}>
                <h5 onClick={() => ScrollToEl(`#${item.title}`, 120)}>
                  {item.title}
                </h5>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      <section className="items-section">
        {olderProjectsData.map((item, index) => (
          <div className="item" key={index} id={item.title}>
            <h2 className="item__title anim-reveal-parent">{item.title}</h2>
            <p
              className="item__description anim-reveal-parent"
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></p>
            <div className="item__tiles-container">
              {item.tiles.map((tile, index) => (
                <div
                  className={`item__tile item__tile--${tile.type}`}
                  key={index}
                >
                  <div className="item__tile-content">
                    {tile.type === "comparison" && (
                      <ComparisonImage
                        pageData={pageData}
                        beforeImg={tile.beforeImg}
                        beforeImgStyle={{ objectFit: "cover" }}
                        afterImg={tile.afterImg}
                        afterImgStyle={{ objectFit: "cover" }}
                      />
                    )}
                    {tile.type === "after" && (
                      <div className="anim-reveal-parent h-full">
                        <Image
                          pageData={pageData}
                          src={tile.afterImg}
                          className="anim-reveal-child"
                        />
                      </div>
                    )}
                    {tile.type === "text" && (
                      <div className="anim-reveal-parent h-full">
                        <p dangerouslySetInnerHTML={{ __html: tile.text }}></p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}

export default OlderProjectsPage
