/*

    <ComparisonImage
    ImgComponent={CliftonImg}
    beforeImg="exterior2Before"
    beforeImgStyle={{ objectFit: "cover" }}
    afterImg="exterior2After"
    />


*/

import { AnimatePresence, motion } from "framer-motion"
import React, { useRef, useState } from "react"
import { useMouse } from "react-use"
import Image from "../assets/images/Image"
import { defaultTransition } from "../utils/easings"

import { AiOutlineClose } from "react-icons/ai"

import "./ComparisonImage.scss"

import { useContext } from "react"
import { context } from "../../provider"

const ToggleBeforeAfterToolTip = () => {
  const { comparisonTooltip, setComparisonTooltip } = useContext(context)

  const closeToolTip = () =>
    setComparisonTooltip({ ...comparisonTooltip, isActive: false })

  return (
    <AnimatePresence>
      {comparisonTooltip.isActive && (
        <motion.div
          className="toggle-before-after__tool-tip"
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
        >
          <p>
            You can toggle between before and after images with these buttons.{" "}
            <br />
            <span onClick={closeToolTip}>Got it!</span>
          </p>
          <AiOutlineClose onClick={closeToolTip} className="cursor-pointer" />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const ToggleBeforeAfter = ({ isViewingBefore, setIsViewingBefore }) => {
  const isTooltipActive = true

  return (
    <div className="toggle-before-after">
      <motion.div
        className="toggle-before-after__btn"
        onClick={() => setIsViewingBefore(!isViewingBefore)}
      >
        <span>{isViewingBefore ? "before" : "after"}</span>
      </motion.div>
      <ToggleBeforeAfterToolTip />
    </div>
  )
}

const TouchDeviceBeforeAfterButton = ({ isViewingBefore }) => {}

const CustomCursor = ({ ref2, isViewingBefore }) => {
  const { elX, elY } = useMouse(ref2)

  return (
    <motion.div
      className="custom-cursor"
      initial={{ x: -64, y: -64 }}
      animate={{
        x: elX ? elX - 32 : -64,
        y: elX ? elY - 32 : -64,
      }}
      transition={{ duration: 0 }}
    >
      <span>{isViewingBefore ? "before" : "after"}</span>
    </motion.div>
  )
}
const ComparisonImage = ({
  pageData,
  beforeImg,
  beforeImgStyle = { objectFit: "contain" },
  afterImg,
  afterImgStyle = {},
}) => {
  const { isTouchDevice } = useContext(context)
  const [isViewingBefore, setIsViewingBefore] = useState(false)
  const [isMouseActive, setisMouseActive] = useState(false)

  const ref = useRef(null)

  const handleMouseMove = e => {
    setisMouseActive(true)
  }

  return (
    <div className="relative h-full anim-reveal-parent">
      <ToggleBeforeAfter
        isViewingBefore={isViewingBefore}
        setIsViewingBefore={setIsViewingBefore}
      />

      <div
        className="comparison-image anim-reveal-child"
        // style={{ cursor: isTouchDevice ? "" : "none" }}
        onClick={() => setIsViewingBefore(!isViewingBefore)}
        onMouseMove={e => handleMouseMove(e)}
        onMouseLeave={e => {
          setisMouseActive(false)
        }}
        ref={ref}
      >
        {/* {isMouseActive && !isTouchDevice && (
          <CustomCursor ref2={ref} isViewingBefore={isViewingBefore} />
        )}
        {isTouchDevice && ( */}
        {/* )} */}
        <motion.div
          className="comparison-image__inner-container"
          initial={{ x: "calc(-100% - 2.5rem)" }}
          animate={{ x: isViewingBefore ? "0%" : "calc(-100% - 2.5rem)" }}
          transition={{ ...defaultTransition, duration: 0.6 }}
        >
          <div className="comparison-image__before-container">
            <div
              className="img-container  parallax-trigger"
              style={{ margin: "0" }}
            >
              <div className=" h-full">
                <Image
                  pageData={pageData}
                  src={beforeImg}
                  imgStyle={beforeImgStyle}
                  className="parallax-transform"
                />
              </div>
            </div>
          </div>
          <div className="comparison-image__after-container">
            <div
              className="img-container  parallax-trigger"
              style={{ margin: "0" }}
            >
              <div className=" h-full">
                <Image
                  pageData={pageData}
                  src={afterImg}
                  imgStyle={afterImgStyle}
                  className="parallax-transform"
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <Image
        pageData={pageData}
        src={afterImg}
        imgStyle={afterImgStyle}
        className="opacity-0"
      />
    </div>
  )
}

export default ComparisonImage
