import { graphql } from "gatsby"

import OlderProjectsPage from "../components/older-projects"

export default OlderProjectsPage

export const query = graphql`
  query OlderProjectsImages {
    CWbathroom1After: file(
      relativePath: { eq: "older-projects/cottonwood/Bathroom+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWbathroom1Before: file(
      relativePath: { eq: "older-projects/cottonwood/bathroom+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWbedroom2Before: file(
      relativePath: { eq: "older-projects/cottonwood/bedroom+2+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWbedroom2After: file(
      relativePath: { eq: "older-projects/cottonwood/bedroom+2+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWdining1After: file(
      relativePath: { eq: "older-projects/cottonwood/dining+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWdining1Before: file(
      relativePath: { eq: "older-projects/cottonwood/dining+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWexterior2Before: file(
      relativePath: { eq: "older-projects/cottonwood/exterior+2+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWexterior2After: file(
      relativePath: { eq: "older-projects/cottonwood/exterior+2+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWkitchen2Before: file(
      relativePath: { eq: "older-projects/cottonwood/kitchen+2+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWkitchen2After: file(
      relativePath: { eq: "older-projects/cottonwood/kitchen+2+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWlounge15Before: file(
      relativePath: { eq: "older-projects/cottonwood/lounge+1.5+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWlounge15After: file(
      relativePath: { eq: "older-projects/cottonwood/lounge+1.5+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWlounge1Before: file(
      relativePath: { eq: "older-projects/cottonwood/lounge+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CWlounge1After: file(
      relativePath: { eq: "older-projects/cottonwood/lounge+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    CWloungeDining1After: file(
      relativePath: { eq: "older-projects/cottonwood/lounge+dining+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHbathroom1After: file(
      relativePath: { eq: "older-projects/gardenhill/bathroom1After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHbathroom1Before: file(
      relativePath: { eq: "older-projects/gardenhill/bathroom1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHbathroomFeature: file(
      relativePath: { eq: "older-projects/gardenhill/bathroomFeature.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHdining1After: file(
      relativePath: { eq: "older-projects/gardenhill/dining1After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHdining1Before: file(
      relativePath: { eq: "older-projects/gardenhill/dining1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHkitchen1After: file(
      relativePath: { eq: "older-projects/gardenhill/kitchen1After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHkitchen1Before: file(
      relativePath: { eq: "older-projects/gardenhill/kitchen1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHkitchen2After: file(
      relativePath: { eq: "older-projects/gardenhill/kitchen2After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHkitchen2Before: file(
      relativePath: { eq: "older-projects/gardenhill/kitchen2Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHlounge1After: file(
      relativePath: { eq: "older-projects/gardenhill/lounge1After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHlounge1Before: file(
      relativePath: { eq: "older-projects/gardenhill/lounge1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GHlounge2After: file(
      relativePath: { eq: "older-projects/gardenhill/lounge2After.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBbathroom1Before: file(
      relativePath: { eq: "older-projects/huntsbury/bathroom+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBbathroom1After: file(
      relativePath: { eq: "older-projects/huntsbury/bathroom+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBbed1After: file(
      relativePath: { eq: "older-projects/huntsbury/bed+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBbed1Before: file(
      relativePath: { eq: "older-projects/huntsbury/bed+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBbed2After: file(
      relativePath: { eq: "older-projects/huntsbury/bed+2+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBentry1After: file(
      relativePath: { eq: "older-projects/huntsbury/entry+1+after.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBentry1Before: file(
      relativePath: { eq: "older-projects/huntsbury/entry+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior1After: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+1+after.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior1Before: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior15After: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+1.5+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior2After: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+2+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior2Before: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+2+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior3After: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+3+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBexterior3Before: file(
      relativePath: { eq: "older-projects/huntsbury/exterior+3+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBkitchen1After: file(
      relativePath: { eq: "older-projects/huntsbury/kitchen+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBkitchen1Before: file(
      relativePath: { eq: "older-projects/huntsbury/kitchen+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBlounge1After: file(
      relativePath: { eq: "older-projects/huntsbury/lounge+1+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBlounge1Before: file(
      relativePath: { eq: "older-projects/huntsbury/lounge+1+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBsunroomAfter: file(
      relativePath: { eq: "older-projects/huntsbury/sunroom+after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HBsunroomBefore: file(
      relativePath: { eq: "older-projects/huntsbury/sunroom+before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPbathroom1After: file(
      relativePath: { eq: "older-projects/marine-parade/bathroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPbathroom1Before: file(
      relativePath: { eq: "older-projects/marine-parade/bathroom1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPbathroom2After: file(
      relativePath: { eq: "older-projects/marine-parade/bathroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPexterior1After: file(
      relativePath: { eq: "older-projects/marine-parade/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPexterior1Before: file(
      relativePath: { eq: "older-projects/marine-parade/exterior1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPexterior2After: file(
      relativePath: { eq: "older-projects/marine-parade/exterior2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPkitchen1After: file(
      relativePath: { eq: "older-projects/marine-parade/kitchen1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPkitchen1Before: file(
      relativePath: { eq: "older-projects/marine-parade/kitchen1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPkitchen2After: file(
      relativePath: { eq: "older-projects/marine-parade/kitchen2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPkitchen2Before: file(
      relativePath: { eq: "older-projects/marine-parade/kitchen2Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPliving1After: file(
      relativePath: { eq: "older-projects/marine-parade/living1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPliving1Before: file(
      relativePath: { eq: "older-projects/marine-parade/living1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPpateo1After: file(
      relativePath: { eq: "older-projects/marine-parade/pateo1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MPpateo2After: file(
      relativePath: { eq: "older-projects/marine-parade/pateo2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHbathroom1After: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/bathroom1After.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHbathroom1Before: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/bathroom1Before.png"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHbathroom2After: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/bathroom2After.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHexterior1After: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/exterior1After.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHexterior1Before: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/exterior1Before.png"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHexterior2After: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/exterior2After.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    #   RHbathroomFeature: file(
    #     relativePath: {
    #       eq: "older-projects/rockinghorse-road/bathroomFeature.jpeg"
    #     }
    #   ) {
    #     childImageSharp {
    #       fluid(maxHeight: 400, quality: 85) {
    #         ...GatsbyImageSharpFluid_withWebp
    #       }
    #     }
    #   }

    RHkitchen1After: file(
      relativePath: { eq: "older-projects/rockinghorse-road/kitchen1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHkitchen1Before: file(
      relativePath: {
        eq: "older-projects/rockinghorse-road/kitchen1Before.png"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    RHkitchen2After: file(
      relativePath: { eq: "older-projects/rockinghorse-road/kitchen2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHlounge1After: file(
      relativePath: { eq: "older-projects/rockinghorse-road/lounge1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHlounge1Before: file(
      relativePath: { eq: "older-projects/rockinghorse-road/lounge1Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHlounge2After: file(
      relativePath: { eq: "older-projects/rockinghorse-road/lounge2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    RHlounge2Before: file(
      relativePath: { eq: "older-projects/rockinghorse-road/lounge2Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSbathroom1After: file(
      relativePath: { eq: "older-projects/wisteria/bathroom1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSbathroom1Before: file(
      relativePath: { eq: "older-projects/wisteria/bathroom1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSbedroom1Before: file(
      relativePath: { eq: "older-projects/wisteria/bedroom1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSbedroom1After: file(
      relativePath: { eq: "older-projects/wisteria/bedroom1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSensuite1After: file(
      relativePath: { eq: "older-projects/wisteria/ensuite1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSensuite1Before: file(
      relativePath: { eq: "older-projects/wisteria/ensuite1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSkitchen1After: file(
      relativePath: { eq: "older-projects/wisteria/kitchen1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSkitchen1Before: file(
      relativePath: { eq: "older-projects/wisteria/kitchen1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSkitchen2After: file(
      relativePath: { eq: "older-projects/wisteria/kitchen2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSlounge1After: file(
      relativePath: { eq: "older-projects/wisteria/lounge1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSlounge2After: file(
      relativePath: { eq: "older-projects/wisteria/lounge2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSlounge2Before: file(
      relativePath: { eq: "older-projects/wisteria/lounge2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSlounge3After: file(
      relativePath: { eq: "older-projects/wisteria/lounge3After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    WSlounge3Before: file(
      relativePath: { eq: "older-projects/wisteria/lounge3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
