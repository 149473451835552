import { ScrollToPlugin } from "gsap/all"
import gsap, { Power1, Power2, Power3, Power4 } from "gsap"
import { useWindowScroll, useWindowSize } from "react-use"
gsap.registerPlugin(ScrollToPlugin)

// (distance / windowHeight * 0.5) + 1

const ScrollToEl = (destination, offsetY = -5) => {
  const windowHeight = window.innerHeight
  const scrollY = window.scrollY

  let destinationEl
  if (typeof destination === "object") {
    destinationEl = destination
  } else {
    destinationEl = document.querySelector(destination)
  }
  const scrollDistance = Math.abs(destinationEl.getBoundingClientRect().y)

  const duration = (scrollDistance / windowHeight) * 0.2 + 1

  console.log({ windowHeight, scrollY, duration })
  gsap.to(window, {
    scrollTo: { y: destination, offsetY },
    duration,
    ease: returnEase(duration),
  })
}
export default ScrollToEl

const returnEase = duration => {
  if (!duration) return
  if (duration < 2) return Power2.easeInOut
  if (duration < 3) return Power3.easeInOut
  if (duration < 4) return Power3.easeInOut
  return Power4.easeInOut
}
