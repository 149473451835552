const olderProjectsData = [
  {
    title: "Cottonwood",
    description: `This house had great bones with two living areas, open plan kitchen dining and large outdoor living it just needed some updating.`,
    tiles: [
      {
        type: "comparison",
        beforeImg: "CWkitchen2Before",
        afterImg: "CWkitchen2After",
      },

      {
        type: "comparison",
        beforeImg: "CWdining1Before",
        afterImg: "CWdining1After",
      },
      { type: "after", afterImg: "CWloungeDining1After" },

      {
        type: "comparison",
        beforeImg: "CWlounge15Before",
        afterImg: "CWlounge15After",
      },

      {
        type: "comparison",
        beforeImg: "CWlounge1Before",
        afterImg: "CWlounge1After",
      },
      {
        type: "comparison",
        beforeImg: "CWexterior2Before",
        afterImg: "CWexterior2After",
      },

      {
        type: "comparison",
        beforeImg: "CWbedroom2Before",
        afterImg: "CWbedroom2After",
      },
      {
        type: "comparison",
        beforeImg: "CWbathroom1Before",
        afterImg: "CWbathroom1After",
      },
    ],
  },
  {
    title: "Huntsbury",
    description: `
    What a big job! we ended up reconfiguring a lot of the home so that the kitchen and living were at the right end of the home, where it got lots of sun and stunning views.
   
    `,

    tiles: [
      {
        type: "comparison",
        beforeImg: "HBexterior3Before",
        afterImg: "HBexterior3After",
      },
      {
        type: "comparison",
        beforeImg: "HBexterior2Before",
        afterImg: "HBexterior2After",
      },
      {
        type: "comparison",
        beforeImg: "HBexterior1Before",
        afterImg: "HBexterior1After",
      },

      {
        type: "comparison",
        beforeImg: "HBbathroom1Before",
        afterImg: "HBbathroom1After",
      },
      {
        type: "comparison",
        beforeImg: "HBbed1Before",
        afterImg: "HBbed1After",
      },

      {
        type: "after",
        afterImg: "HBbed2After",
      },
      {
        type: "comparison",
        beforeImg: "HBentry1Before",
        afterImg: "HBentry1After",
      },
    ],
  },
  {
    title: "Gardenhill",
    description: ``,
    tiles: [
      {
        type: "comparison",
        beforeImg: "GHbathroom1Before",
        afterImg: "GHbathroom1After",
      },

      {
        type: "after",
        afterImg: "GHbathroomFeature",
      },
      {
        type: "comparison",
        beforeImg: "GHdining1Before",
        afterImg: "GHdining1After",
      },
      {
        type: "comparison",
        beforeImg: "GHkitchen1Before",
        afterImg: "GHkitchen1After",
      },

      {
        type: "comparison",
        beforeImg: "GHkitchen2Before",
        afterImg: "GHkitchen2After",
      },
      {
        type: "comparison",
        beforeImg: "GHlounge1Before",
        afterImg: "GHlounge1After",
      },
      {
        type: "after",
        afterImg: "GHlounge2After",
      },
    ],
  },
  {
    title: "Rockinghorse Road",
    description: ``,
    tiles: [
      {
        type: "comparison",
        beforeImg: "RHbathroom1Before",
        afterImg: "RHbathroom1After",
      },

      {
        type: "after",
        afterImg: "RHbathroom2After",
      },

      {
        type: "comparison",
        beforeImg: "RHexterior1Before",
        afterImg: "RHexterior1After",
      },
      {
        type: "after",
        afterImg: "RHexterior2After",
      },

      {
        type: "comparison",
        beforeImg: "RHkitchen1Before",
        afterImg: "RHkitchen1After",
      },
      {
        type: "after",
        afterImg: "RHkitchen2After",
      },
      {
        type: "comparison",
        beforeImg: "RHlounge1Before",
        afterImg: "RHlounge1After",
      },
      {
        type: "comparison",
        beforeImg: "RHlounge2Before",
        afterImg: "RHlounge2After",
      },
    ],
  },
  {
    title: "Wisteria",
    description: ``,
    tiles: [
      {
        type: "comparison",
        beforeImg: "WSbathroom1Before",
        afterImg: "WSbathroom1After",
      },
      {
        type: "comparison",
        beforeImg: "WSbedroom1Before",
        afterImg: "WSbedroom1After",
      },
      {
        type: "comparison",
        beforeImg: "WSensuite1Before",
        afterImg: "WSensuite1After",
      },
      {
        type: "comparison",
        beforeImg: "WSkitchen1Before",
        afterImg: "WSkitchen1After",
      },
      {
        type: "after",
        afterImg: "WSkitchen2After",
      },
      {
        type: "after",
        afterImg: "WSlounge1After",
      },
      {
        type: "comparison",
        beforeImg: "WSlounge2Before",
        afterImg: "WSlounge2After",
      },
      {
        type: "comparison",
        beforeImg: "WSlounge3Before",
        afterImg: "WSlounge3After",
      },
    ],
  },
  {
    title: "Marine Parade",
    description: ``,
    tiles: [
      {
        type: "comparison",
        beforeImg: "MPbathroom1Before",
        afterImg: "MPbathroom1After",
      },
      {
        type: "after",
        afterImg: "MPbathroom2After",
      },
      {
        type: "comparison",
        beforeImg: "MPexterior1Before",
        afterImg: "MPexterior1After",
      },
      {
        type: "after",
        afterImg: "MPexterior2After",
      },
      {
        type: "comparison",
        beforeImg: "MPkitchen1Before",
        afterImg: "MPkitchen1After",
      },
      {
        type: "comparison",
        beforeImg: "MPkitchen2Before",
        afterImg: "MPkitchen2After",
      },
      {
        type: "comparison",
        beforeImg: "MPliving1Before",
        afterImg: "MPliving1After",
      },

      {
        type: "after",
        afterImg: "MPpateo1After",
      },
      {
        type: "after",
        afterImg: "MPpateo2After",
      },
    ],
  },
]

export default olderProjectsData
